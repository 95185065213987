export const initReviewsCaro = () => {
	document.querySelector('#reviews').addEventListener('click', e => {
		const clickedButton = e.target.closest('button');

		if(clickedButton.classList.contains('close_overlay')) {
			clickedButton.closest('.card_full_text').removeAttribute('show');
		} else {
			if(clickedButton) {
				const theCard = e.target.closest('.review_card');
				theCard.querySelector('.card_full_text').setAttribute('show', true);
			}
		}
	});
};