export const feedbackPage = (function() {
	let my = {};

	my.init = function () {

		const pageContainer = document.querySelector('#container');
		const headerArea = document.querySelector('#reviews_header');
		const facilitySelect = document.querySelector('#select_facility');
		const feedbackSelect = document.querySelector('#facility_feedback');
		const feedbackFacility = document.querySelectorAll('.facility_selection');
		const contactForm = document.querySelector('#contact_form');
		const facilityField = document.querySelector('#facility_input');
		let selectedFacility = false;
	
		window.addEventListener("load", () => {
			headerArea.style.height = window.innerHeight + 'px';
		});

		function selectFacility(facility) {
			facilitySelect.removeAttribute('data-active');
			feedbackSelect.setAttribute('data-active','');
			facilityField.setAttribute('value',facility);
			feedbackFacility.forEach( item => {
				if (item.getAttribute('data-facility') === facility) item.setAttribute('data-active','');
			});
		}

		facilitySelect.addEventListener('click',(e) => {
			if (e.target.tagName === 'BUTTON') {
				selectFacility(e.target.value);
				selectedFacility = true;
				e.stopPropagation();
			}
		});

		facilitySelect.addEventListener('mouseover',(e) => {
			if (e.target.tagName === 'BUTTON') {
				headerArea.setAttribute('data-active','');
				headerArea.setAttribute('data-facility',e.target.getAttribute('data-facility'));
			}
		});

		facilitySelect.addEventListener('mouseout',(e) => {
			if (e.target.tagName === 'BUTTON' && selectedFacility === false) headerArea.removeAttribute('data-active');
		});

		feedbackSelect.addEventListener('click',(e) => {
			if (e.target.tagName === 'BUTTON') {
				pageContainer.style.height = 'auto';
				contactForm.scrollIntoView({behavior: 'smooth'});
			}
		});

	};

	return my;

})();