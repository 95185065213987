import { AutomatitCarousel } from './automatit_carousel.m';

export const aboutScript = (function() {
	let my = {};

	function _aboutCaro() {
		const aboutCaroImgs = [
			{src: "/dist/images/content/slider_2.jpg", alt: ""},
			{src: "/dist/images/content/slider_3.jpg", alt: ""},
			{src: "/dist/images/content/slider_4.jpg", alt: ""},
			{src: "/dist/images/content/slider_5.jpg", alt: ""},
		];

		AutomatitCarousel({
			element: document.querySelector('#about_caro'),
			images: aboutCaroImgs,
			imagesAsBackgrounds: true,
			showDots: true
		});
	}

	my.init = function () {
		if(document.getElementById('about_caro')) {
			_aboutCaro();
		}
	};

	return my;
})();
