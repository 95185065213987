import { AutomatitCarousel } from './automatit_carousel.m';

export const homeCaro = (function() {
	let my = {};

	function _homeAboutCaro() {
		const homeAboutCaroImgs = [
			{src: "/dist/images/content/slider_2.jpg", alt: ""},
			{src: "/dist/images/content/slider_3.jpg", alt: ""},
			{src: "/dist/images/content/slider_4.jpg", alt: ""},
			{src: "/dist/images/content/slider_5.jpg", alt: ""},
		];

		AutomatitCarousel({
			element: document.querySelector('#home_about_caro'),
			images: homeAboutCaroImgs,
			imagesAsBackgrounds: true,
			showDots: true
		});
	}

	my.init = function () {
		if(document.getElementById('home_about_caro')) {
			_homeAboutCaro();
		}
	};

	return my;
})();